#filters {
  height: 45px;
  padding: 10px;
}

#locSearchLabel {
  float: left;
  padding: 4px;
}

.topbarDistance {
  padding: 10px; 
}

.table-responsive {
  border: 1px solid #fafafa;
  margin-top: 2px;
  background-color: #fafafa;
  max-height: 100%;
  overflow: auto;
}

.clickable {
  text-decoration: none;
  cursor: pointer;
  color: initial;
}

.locDistHead th {
  border-bottom: 1px solid #ddd !important;
}

.locDistHead th:hover {
  background-color: white !important;
}

.locDistHead td {
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
}

.locDistHead tr:nth-child(odd) {
  background-color: #fafafa;
}

.locDistHead tr:nth-child(even) {
  background-color: white;
}

.clickable tr:hover {
  background-color: #ddd !important;
}

.filter {
  padding-right: 0;
  margin-right: 5px;
}

.filterAvail {
  padding-left: 0;
  margin-right: -5px;
}

.allocation {
  padding-right: 0;
  margin-right: 5px;
}

.availability {
  padding-left: 0;
  margin-right: -5px;
}

#filterBar {
  min-width: 63em;
  padding: 5px;
  height: 7vh;
}

#locationFilterDiv,
#locationFilter,
#locationSearchDiv,
#locationSearchFilter {
  margin: 0px 5px;
}

#locationSearchFilter,
#locationFilter {
  border: none;
}

#locationSearchMagnifier,
#locationFilterMagnifier {
  background-color: white;
  border: none;
}

#locationFilterDiv,
#locationSearchDiv {
  margin-top: 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white;
  float: left;
}

#locationFilter,
#locationSearchFilter {
  width: 100px;
}

#addDistanceButton {
  float: right;
  margin: 2px 25px;
  padding: 2px 20px;
}

#dayFilter {
  float: left;
}

.hiddenButton {
  display: unset;
  width: 26.0781px;
  padding: 1px 7px 2px 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border: 2px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
}
