#filters {
  height: 45px;
  padding: 10px;
}

#SearchLabel {
  float: left;
  padding: 4px;
}

.reasonHead td {
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
}
.reasonHead tr:nth-child(odd) {
  background-color: #fafafa;
}
.reasonHead tr:nth-child(even) {
  background-color: white;
}

#clickable tr:hover {
  background-color: #ddd;
}
.reasonHead th {
  border-bottom: 1px solid #ddd !important;
  border-top: 1px solid #ddd !important;
}
.reasonHead th:hover {
  background-color: white !important;
}

.table-responsive {
  border: 1px solid #fafafa;
  margin-top: 2px;
  max-height: 100%;
  overflow: auto;
}

.clickable {
  text-decoration: none;
  cursor: pointer;
  color: initial;
}

.filter {
  padding-right: 0;
  margin-right: 5px;
}



#locationSearchFilter,
#locationFilter,
#assetFilter {
  border: none;
}

#locationSearchMagnifier,
#locationFilterMagnifier,
#assetFilterMagnifier {
  background-color: white;
  border: none;
}

#startTimeDiv {
  float: left;
  margin: 1px 5px !important;
}

#endTimeDiv {
  float: left;
  margin: 1px 5px !important;
}

#FilterDiv,
#SearchDiv {
  margin-top: 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white;
  float: left;
}

#locationFilter,
#locationSearchFilter,
#assetFilter {
  width: 100px;
}

#assetFilterDiv {
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 3px;
  float: left;
  margin-top: 1px;
}

.assetSearch {
  background-color: #fafafa;
  padding: 5px;
  min-width: 210px;
  height: 7vh;
}

#addButton {
  float: right;
  margin: 2px 25px;
  padding: 2px 20px;
}

#dayFilter {
  float: left;
}

.hiddenButton {
  display: unset;
  width: 26.0781px;
  padding: 1px 7px 2px 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border: 2px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
}

.vertScroll {
  max-height: 85vh;
}

.diagramLegCol.droppable.over {
  border-top: 2px dotted black !important;
  border-bottom: 2px dotted black !important;
  border-left: none !important;
  border-right: none !important;
  cursor: move !important;
}

.diagramLegCol:first-child.droppable.over {
  border-left: 2px dotted black !important;
  border-right: none !important;
  cursor: move !important;
}

.diagramLegCol:last-child.droppable.over {
  border-left: none !important;
  border-right: 2px dotted black !important;
  cursor: move !important;
}

div.allocation > div.table-responsive {
  max-height: 85vh;
}

.list-group.allocatedAssets > .list-group-item {
  padding: 0px 0px;
}

.allocatedAsset > .remove {
  cursor: pointer;
}

li.list-group-item.draggable.dragging {
  border: 1px dotted black;
}
#resolvedCheckbox {
  margin-left: 10px;
  margin-top: 5px;
}

#resolvedLabel {
  float: left;
}
#unitLabel {
  float: left;
  margin-left: auto;
  margin-right: auto;
}
