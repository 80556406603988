@media (min-width: 280px) {
    #content:not(.content-alt) {
        padding-left: 5px !important;
        padding-right: 15px;
    }
}

#header .hi-trigger {
    display: inline-block !important;
}

#header {
    background-color: #F3F3F7;
}

#content {
    margin: -25px -25px -25px -15px;
    height: 100%;
}

.container {
    height: 100%;
}

.trimbleStyleButton {
    background: #E2E2E7;
    color: #363545;
    text-transform: none;
}

.trimbleStyleButton:hover {
    background: #F9F9FB;
}

.trimbleStyleButton:active {
    background: #7B7A86;
}

.trimbleStyleButton:disabled {
    background: #363545;
    color: #FFFFFF;
}

.trimbleStyleBorder {
    margin-top: 2px;
    border: 1px solid lightgray;
    border-radius: 3px;
}

.d2m-result-table {
    border: 1px solid #fafafa;
    border-collapse: collapse;
    max-height: 100%;
    width: 100%;
    margin-top: 2px;
    background-color: #fafafa;
    overflow: auto;
}
  
.d2m-result-table td,
.d2m-result-table th {
    border-bottom: 1px solid #ddd !important;
    border-top: 1px solid #ddd !important;
}
  
.d2m-result-table tr:nth-child(odd) {
    background-color: #fafafa;
}

.d2m-result-table tr:nth-child(even) {
    background-color: white;
}
  
.d2m-result-table tr:hover {
    background-color: #ddd;
}

.d2m-result-table th:hover {
    background-color: #ddd !important;
}
  
.d2m-result-table th {
    text-align: left;
    background-color: white;
    color: black;
}
  
.searchFieldDiv {
    margin-top: 2px;
    border: 1px solid lightgray;
    border-radius: 3px;
    background-color: white;
    float: left;
    margin: 0px 5px;
}

.searchFieldDiv input {
    width: 100px;
    border: none;
    margin: 0px 5px;
}

.searchPanelButton {
    margin-left: 10px;
    float: left;
    margin: 2px 10px;
    padding: 2px 7px;
}

.searchPanelButton.add {
    float: right;
    margin: 2px 25px;
    padding: 2px 20px;
}

.searchPanelButton.clearSearchField {
    float: left;
    margin: 2px 10px;
    padding: 2px 7px;
}

.searchFieldDiv .searchFieldMagnifier {
    background-color: white;
    border: none;
}

.clickableColumn {
    cursor: pointer;
}

.filtersColumn {
    padding-right: 0;
    margin-right: 5px;
}

.filtersPanel {
    height: 45px;
    padding: 10px;
}

.text-no-wrap {
    white-space: nowrap;
}

.compact.d2m-result-table > tbody > tr > td:first-child,
.compact.d2m-result-table > tbody > tr > th:first-child,
.compact.d2m-result-table > tfoot > tr > td:first-child,
.compact.d2m-result-table > tfoot > tr > th:first-child,
.compact.d2m-result-table > thead > tr > td:first-child,
.compact.d2m-result-table > thead > tr > th:first-child {
    padding-left: 5px;
}

.compact.d2m-result-table > tbody > tr > td,
.compact.d2m-result-table > tbody > tr > th,
.compact.d2m-result-table > tfoot > tr > td,
.compact.d2m-result-table > tfoot > tr > th,
.compact.d2m-result-table > thead > tr > td,
.compact.d2m-result-table > thead > tr > th {
    padding: 5px;
}

.modal-xl {
  width: 90%;
  max-width:1200px;
}

.table >tbody > tr:last-child > td,
.table > tfoot > tr:last-child > td {
    padding-bottom: 10px;
}

th.clickableColumn .fa.fa-sort-up,
th.clickableColumn .fa.fa-sort-down {
    padding-right: 5px;
}

.compactColumnSides > tbody > tr > td,
.compactColumnSides > tbody > tr > th,
.compactColumnSides > tfoot > tr > td,
.compactColumnSides > tfoot > tr > th,
.compactColumnSides > thead > tr > td,
.compactColumnSides > thead > tr > th {
    padding-left: 2.5px;
    padding-right: 2.5px;
}

.compactFirstColumn > tbody > tr > td:first-child,
.compactFirstColumn > tbody > tr > th:first-child,
.compactFirstColumn > tfoot > tr > td:first-child,
.compactFirstColumn > tfoot > tr > th:first-child,
.compactFirstColumn > thead > tr > td:first-child,
.compactFirstColumn > thead > tr > th:first-child {
    padding-left: 10px;
}

