#availability, #allocation {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#availability td, #allocation td,
#availability th, #allocation th {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

#allocation tbody:nth-child(odd) {
  background-color: white;
}

.diagramLegRow:nth-child(even) {
  background-color: white;
}

.diagramLegRow:nth-child(odd) {
  background-color: #fafafa;
}

#availability tr:nth-child(odd) {
  background-color: #fafafa;
}
#availability tr:nth-child(even) {
  background-color: white;
}

#availability tr:hover, #allocation tr:hover {
  background-color: #ddd;
}

#availability th, #allocation th {
  text-align: left;
  background-color: white;
  color: black;
}

tr.diagramLegRow > th {
  background-color: white !important;
  font-weight: normal !important;
  color: black !important;
}

.table-responsive {
  border: 1px solid #fafafa;
  margin-top: 2px;
  background-color: #fafafa;
  max-height: 100%;
  overflow: auto;
}

.calendarDiv {
  float: right;
  margin-right: 20px;
}

.availabilitySectionToggle {
  float: right;
  margin-right: 10px;
  margin-top: 0px;
}

.react-datepicker {
  font-size: 1.3rem !important;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.3rem !important;
}

.react-datepicker__input-container > input {
  width: 100px;
  border: 1px solid lightgray;
  border-radius: 3px;
}

.clickableAsset {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}

.filter {
  padding-right: 0;
  margin-right: 5px;
}

.filterAvail {
  padding-left: 0;
  margin-right: -5px;
}

.allocation {
  padding-right: 0;
  margin-right: 5px;
}

.allocation.fullWidth {
  padding-right: 10px;
}

.availability {
  padding-left: 0;
  margin-right: -5px;
}

#filterBar {
  min-width: 63em;
  padding: 5px;
  height: 7vh;
}

.startTimeDiv,
.startTimeFilter,
.endTimeDiv,
.endTimeFilter,
#locationFilterDiv,
#locationFilter,
#quickSearchDiv,
#quickSearchFilter,
#assetFilter,
#assetFilterDiv {
  margin: 0px 5px;
}

.startTimeForm,
.endTimeForm {
  display: inline;
}

.startTimeDiv span.fieldLabel {
  min-width: 80px;
  display: inline-block;
}

.startTimeDiv span.fieldLabelFrom,
.endTimeDiv span.fieldLabelTo {
  font-size: smaller;
  font-style: italic;
  display: inline-block;
}

.timeFilterDiv.departureField {
  margin-top: 5px;
}

.arrivalDepartureFilter {
  float: left;
}

#quickSearchFilter,
#locationFilter,
#assetFilter {
  border: none;
}

#quickSearchMagnifier,
#locationFilterMagnifier,
#assetFilterMagnifier {
  background-color: white;
  border: none;
}

.startTimeDiv {
  margin: 1px 5px !important;
}

.endTimeDiv {
  margin: 1px 5px !important;
}

#locationFilterDiv,
#quickSearchDiv {
  margin-top: 2px;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: white;
  float: left;
}

#locationFilter,
#quickSearchFilter,
#assetFilter {
  width: 100px;
}

#assetFilterDiv {
  border: 1px solid lightgray;
  background-color: white;
  border-radius: 3px;
  float: left;
  margin-top: 1px;
}

.assetSearch {
  background-color: #fafafa;
  padding: 5px;
  min-width: 210px;
  height: 7vh;
}

#clearSearchButton {
  float: left;
  margin: 2px 10px;
  padding: 2px 7px;
}

#dayFilter {
  float: left;
}

.hiddenButton {
  display: unset;
  width: 26.0781px;
  padding: 1px 7px 2px 7px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border: 2px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
}

.vertScroll {
  max-height: 85vh;
}

.diagramLegCol.droppable.over {
  border-top: 2px dotted black !important;
  border-bottom: 2px dotted black !important;
  border-left: none !important;
  border-right: none !important;
  cursor: move !important;
}

.diagramLegCol:first-child.droppable.over {
  border-left: 2px dotted black !important;
  border-right: none !important;
  cursor: move !important;
}

.diagramLegCol:last-child.droppable.over {
  border-left: none !important;
  border-right: 2px dotted black !important;
  cursor: move !important;
}

div.allocation > div.table-responsive {
  max-height: 85vh;
}

.list-group.allocatedAssets > .list-group-item {
  padding: 0px 0px;
}

.allocatedAsset > .remove {
  cursor: pointer;
}

li.list-group-item.draggable.dragging {
  border: 1px dotted black;
}
#resolvedCheckbox {
  margin-left: 10px;
  margin-top: 5px;
}

#resolvedLabel {
  float: left;
}
#unitLabel {
  float: left;
  margin-left: auto;
  margin-right: auto;
}

.nav-tabs.asset-modal {
  border-bottom: 1px solid #dee2e6;
}

.assetRestrictions {
  height: 22px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Refer - https://codepen.io/bsidelinger912/pen/WOdPNK
*/

.assetRestrictionToolTip .react-tooltip-lite {
  border: 1px solid #000;
  background: #fff;
  white-space: nowrap;
}

.assetRestrictionToolTip .react-tooltip-lite-arrow {
  border-color:  #000;
  position: relative;
}

.assetRestrictionToolTip .react-tooltip-lite-arrow::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.assetRestrictionToolTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.assetRestrictionToolTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.assetRestrictionToolTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.assetRestrictionToolTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid #fff;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.td-veh {
  width: 60px;
  white-space: nowrap;
}

.td-int {
  width: 42px;
  white-space: nowrap;
}

.td-class {
  width: 60px;
  white-space: nowrap;
}

.availabilityCaption {
  caption-side: top;
  font-size: smaller;
  font-weight: bold;
  color: red;
  margin-left: 10px;
}

.allocationFont {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.fuelLevelMovementTable tbody tr td:nth-child(7),
.fuelLevelMovementTable tbody tr td:nth-child(8),
.fuelLevelMovementTable tbody tr td:nth-child(9),
.fuelLevelMovementTable tbody tr td:nth-child(10),
.fuelLevelMovementTable tbody tr td:nth-child(11),
.fuelLevelMovementTable tbody tr td:nth-child(12),
.fuelLevelMovementTable thead tr th:nth-child(7),
.fuelLevelMovementTable thead tr th:nth-child(8),
.fuelLevelMovementTable thead tr th:nth-child(9),
.fuelLevelMovementTable thead tr th:nth-child(10),
.fuelLevelMovementTable thead tr th:nth-child(11),
.fuelLevelMovementTable thead tr th:nth-child(12),
.fuelLevelMovementTable tfoot tr td {
  text-align: right;
}

.clickableAsset.available {
  min-width: 55px;
  padding: 6px;
  padding-bottom: 8px;
  margin-top: -6px;
  margin-bottom: -6px;
}

 i.assetRemove {
   float:left;
   margin-right: 2px;
   margin-top: 3px;
 }

span.allocatedAsset {
  padding: 3px;
  min-width: 43px;
  margin-right: 2px;
}

ul.diagramLevel.diagramLegLevel {
  min-width: 60px;
}

li.allocatedAsset img.assetWarning {
  float: left;
}

img.assetWarning,
img.eodFuelError,
img.eodFuelWarning {
  height: 18px;
  width: 18px;
  margin-right: 2px;
}

.allocatedAssetToolTip .react-tooltip-lite {
  padding: 3px;
  font-size: smaller;
  font-weight: bold;
  white-space: nowrap;
}

span.locationBox {
  padding: 6px;
  margin-left: 3px;
}

tr.legContainerRow,
tr.legContainerRow > td {
  padding: 0px !important;
  margin: 0px !important;
}

table.allocationLegs td:first-child {
  padding-left: 10px;
}

span.interventionBox {
  padding: 6px;
}

.allocatedAssetColumn {
  min-width: 80px;
}
