
.specialContent tr, td {
    padding: 10px;
    margin-top: 20px;
}

.specialContent {
    margin-top: 10px;
}

#specialSaveButton {
    margin: 15px;
}

#specialLeg, #specialUpdate {
    border: solid 1px grey;
}

.specialContent button {
    margin-left: 15px;
}

.specialContent input:disabled {
    color: lightgray;
}  

#specialDistance input:disabled {
    color: gray;
}

.modal-body {
    white-space: pre-wrap;
}